export function getActivityFormattedTime(duration: number): string {
  const minute = 60;
  const hour = 60 * minute;

  const durationInHours = duration / hour;
  const durationInMinutes = duration / minute;

  const hours = parseTimeValue(durationInHours, 'h');
  const minutes = parseTimeValue(durationInMinutes % 60, 'm');
  const seconds = parseTimeValue(duration % 60, 's');

  return `${hours} ${minutes} ${!hours ? seconds : ''}`;
}

function parseTimeValue(value: number, unit: 'h' | 'm' | 's'): string {
  return value >= 1 ? `${Math.floor(value)}${unit}` : '';
}
