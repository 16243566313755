import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import qs from 'qs';

import { arrayBufferToBase64, CommonError, getEnv } from '@/common/utils';
import { httpClient } from '@/core/services/http-client';
import {
  HasGeometryValues,
  TrackedActivitiesElementVM,
  TrackedActivitiesListResponse,
  TrackedActivitiesListVM,
  TrackedActivityDetailsResponse,
  TrackedActivityDetailsVM,
  TrackedActivityParams,
  TrackedActivityThumbnailParam,
} from '@/models/tracked-activities';

const config = getEnv();

const ACTIVITIES_ENDPOINT = `${config.REACT_APP_API_URL}/tracked-activity-api/admin/v1/activities`;

export const getTrackedActivities = (payload: TrackedActivityParams) => {
  const { activityId, activityName, region, status, createdAtFrom, createdAtTo, distanceFrom, distanceTo, activityTypes, hasGeometry, createdBy, ...rest } =
    payload;
  return httpClient()
    .authorized.post<TrackedActivitiesListResponse>(
      `${ACTIVITIES_ENDPOINT}/search`,
      {
        activityId,
        name: activityName,
        region,
        status: status ? [status] : undefined,
        creationDateFrom: createdAtFrom,
        creationDateTo: createdAtTo,
        distanceFrom: toMeters(distanceFrom),
        distanceTo: toMeters(distanceTo),
        activityTypes,
        hasGeometry: hasGeometry ? hasGeometry === HasGeometryValues.Yes : undefined,
        userId: createdBy?.value,
      },
      {
        params: rest,
        paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
      }
    )
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new TrackedActivitiesListVM({ data, VM: TrackedActivitiesElementVM });
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

export const deleteTrackedActivity = (id: string) => {
  return httpClient()
    .authorized.delete(`${ACTIVITIES_ENDPOINT}/${id}`)
    .pipe(
      map(({ status }) => {
        if (status === 204) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

export const restoreTrackedActivity = (id: string) => {
  return httpClient()
    .authorized.post(`${ACTIVITIES_ENDPOINT}/${id}/reactivation`)
    .pipe(
      map(({ status }) => {
        if (status === 204) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

export const getTrackedActivityDetailsData = (id: string) =>
  httpClient()
    .authorized.get<TrackedActivityDetailsResponse>(`${ACTIVITIES_ENDPOINT}/${id}`)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new TrackedActivityDetailsVM(data);
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: e.code, message: e.errorMessage })))
    );

export const getTrackedActivityThumbnailData = (param: TrackedActivityThumbnailParam) =>
  httpClient()
    .authorized.get<any>(param.url, { responseType: 'arraybuffer' })
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          const image = arrayBufferToBase64(data);
          return `data:image/png;base64,${image}`;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

function toMeters(valueInKm: number | undefined): number | undefined {
  if (!valueInKm) {
    return valueInKm;
  }
  return valueInKm * 1000;
}
