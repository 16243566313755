import { css } from '@emotion/core';

export const iconfont = css`
  @font-face {
    font-family: "iconfont";
    src: url('/fonts/Iconfont/iconfont.eot');
    src: url('/fonts/Iconfont/iconfont.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Iconfont/iconfont.woff') format('woff'),
      url('/fonts/Iconfont/iconfont.ttf') format('truetype'),
      url('/fonts/Iconfont/iconfont.svg') format('svg');
  }

  .icon {
    font-style: normal;
    font-weight: 400;

    &:before {
      font-family: "iconfont";
      display: block;
      font-size: inherit;
      text-rendering: auto;
      line-height: inherit;
      -webkit-font-smoothing: antialiased;
    }
    
    &.ap-accordion-content:before {
      content: "\\ea01";
    }
    &.mdi-account-circle:before {
      content: "\\ea02";
    }
    &.mdi-alert-circle-outline:before {
      content: "\\ea03";
    }
    &.mdi-alert-circle:before {
      content: "\\ea04";
    }
    &.mdi-alert-triangle:before {
      content: "\\ea05";
    }
    &.mdi-alert:before {
      content: "\\ea06";
    }
    &.mdi-arrow-collapse-right:before {
      content: "\\ea07";
    }
    &.mdi-arrow-expand-up:before {
      content: "\\ea08";
    }
    &.mdi-arrow-forward:before {
      content: "\\ea09";
    }
    &.mdi-attach-file:before {
      content: "\\ea0a";
    }
    &.mdi-bold:before {
      content: "\\ea0b";
    }
    &.mdi-cancel:before {
      content: "\\ea0c";
    }
    &.mdi-check-circle:before {
      content: "\\ea0d";
    }
    &.mdi-check-decagram:before {
      content: "\\ea0e";
    }
    &.mdi-check:before {
      content: "\\ea0f";
    }
    &.mdi-chevron-down:before {
      content: "\\ea10";
    }
    &.mdi-chevron-left:before {
      content: "\\ea11";
    }
    &.mdi-chevron-right:before {
      content: "\\ea12";
    }
    &.mdi-chevron-up:before {
      content: "\\ea13";
    }
    &.mdi-circle-medium:before {
      content: "\\ea14";
    }
    &.mdi-close:before {
      content: "\\ea15";
    }
    &.mdi-copyright:before {
      content: "\\ea16";
    }
    &.mdi-dark_mode:before {
      content: "\\ea17";
    }
    &.mdi-delete:before {
      content: "\\ea18";
    }
    &.mdi-download:before {
      content: "\\ea19";
    }
    &.mdi-drag-indicator:before {
      content: "\\ea1a";
    }
    &.mdi-email-sync:before {
      content: "\\ea1b";
    }
    &.mdi-email:before {
      content: "\\ea1c";
    }
    &.mdi-eye:before {
      content: "\\ea1d";
    }
    &.mdi-format-paragraph:before {
      content: "\\ea1e";
    }
    &.mdi-format-title:before {
      content: "\\ea1f";
    }
    &.mdi-grab:before {
      content: "\\ea20";
    }
    &.mdi-grid:before {
      content: "\\ea21";
    }
    &.mdi-help:before {
      content: "\\ea22";
    }
    &.mdi-image-multiple:before {
      content: "\\ea23";
    }
    &.mdi-image:before {
      content: "\\ea24";
    }
    &.mdi-information:before {
      content: "\\ea25";
    }
    &.mdi-insert-chart:before {
      content: "\\ea26";
    }
    &.mdi-italic:before {
      content: "\\ea27";
    }
    &.mdi-light_mode:before {
      content: "\\ea28";
    }
    &.mdi-link-off:before {
      content: "\\ea29";
    }
    &.mdi-link:before {
      content: "\\ea2a";
    }
    &.mdi-list:before {
      content: "\\ea2b";
    }
    &.mdi-login:before {
      content: "\\ea2c";
    }
    &.mdi-logout:before {
      content: "\\ea2d";
    }
    &.mdi-map-marker-account:before {
      content: "\\ea2e";
    }
    &.mdi-map-marker:before {
      content: "\\ea2f";
    }
    &.mdi-map:before {
      content: "\\ea30";
    }
    &.mdi-menu-down:before {
      content: "\\ea31";
    }
    &.mdi-menu-swap:before {
      content: "\\ea32";
    }
    &.mdi-menu-up:before {
      content: "\\ea33";
    }
    &.mdi-more-vert-black:before {
      content: "\\ea34";
    }
    &.mdi-page-first:before {
      content: "\\ea35";
    }
    &.mdi-page-last:before {
      content: "\\ea36";
    }
    &.mdi-pencil-off:before {
      content: "\\ea37";
    }
    &.mdi-pencil:before {
      content: "\\ea38";
    }
    &.mdi-playlist-add-check:before {
      content: "\\ea39";
    }
    &.mdi-power-standby:before {
      content: "\\ea3a";
    }
    &.mdi-published-with-changes:before {
      content: "\\ea3b";
    }
    &.mdi-reorder-black:before {
      content: "\\ea3c";
    }
    &.mdi-review:before {
      content: "\\ea3d";
    }
    &.mdi-route-black:before {
      content: "\\ea3e";
    }
    &.mdi-search-off:before {
      content: "\\ea3f";
    }
    &.mdi-send-black:before {
      content: "\\ea40";
    }
    &.mdi-star-circle:before {
      content: "\\ea41";
    }
    &.mdi-timer-sand:before {
      content: "\\ea42";
    }
    &.mdi-translate:before {
      content: "\\ea43";
    }
    &.mdi-videocam:before {
      content: "\\ea44";
    }
    &.mdi-view-headline:before {
      content: "\\ea45";
    }
    &.mdi-volume-high:before {
      content: "\\ea46";
    }
  }
`;