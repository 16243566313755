import { createReducer } from 'typesafe-actions';

import { type FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { type TrackedActivityThumbnailVM } from '@/models/tracked-activities';
import { type FeatureStateType } from '../../helpers';
import { trackedActivitiesActions } from '../actions';

export type trackedActivityThumbnailState = FetchState<TrackedActivityThumbnailVM>;

const INITIAL_STATE: trackedActivityThumbnailState = getFetchInitialState<TrackedActivityThumbnailVM>();

const reducer = createReducer<trackedActivityThumbnailState, trackedActivitiesActions>(INITIAL_STATE)
  .handleAction(trackedActivitiesActions.thumbnail.request, state => fetchReducerHelpers.request(INITIAL_STATE, state))
  .handleAction(trackedActivitiesActions.thumbnail.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(trackedActivitiesActions.thumbnail.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(trackedActivitiesActions.clearTrackedActivityDetails, () => ({ ...INITIAL_STATE }));

export const trackedActivityThumbnailReducer = { thumbnail: reducer };
export type trackedActivityThumbnailReducer = FeatureStateType<typeof trackedActivityThumbnailReducer>;
