import { createReducer } from 'typesafe-actions';

import { type FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { HasGeometryValues, RecordStatusValues, type TrackedActivitiesListVM, type TrackedActivityParams } from '@/models/tracked-activities';
import { type FeatureStateType } from '../../helpers';
import { trackedActivitiesActions } from '../actions';

export type trackedActivitiesListState = FetchState<TrackedActivitiesListVM, TrackedActivityParams>;

const INITIAL_STATE: trackedActivitiesListState = getFetchInitialState<TrackedActivitiesListVM, TrackedActivityParams>({
  status: RecordStatusValues.Active,
  hasGeometry: HasGeometryValues.Yes,
});

const reducer = createReducer<trackedActivitiesListState, trackedActivitiesActions>(INITIAL_STATE)
  .handleAction(trackedActivitiesActions.list.request, (state, { payload }) => fetchReducerHelpers.request(INITIAL_STATE, state, payload))
  .handleAction(trackedActivitiesActions.list.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(trackedActivitiesActions.list.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload));

export const trackedActivitiesListReducer = { list: reducer };
export type trackedActivitiesListReducer = FeatureStateType<typeof trackedActivitiesListReducer>;
