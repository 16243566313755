import { createSelector } from 'reselect';

import type { TrackedActivitiesListVM } from '@/models/tracked-activities';
import { getDataStatus } from '@/common/utils';
import type { trackedActivitiesReducer } from '@/store/tracked-activities/reducers';

const getTrackedActivitiesList = (state: trackedActivitiesReducer) => state.trackedActivities.list;

type TrackedActivitiesList = trackedActivitiesReducer['trackedActivities']['list'];
type MakeGetTrackedActivitiesListData = TrackedActivitiesListVM['list'];
type MakeGetTrackedActivitiesListPaginationData = TrackedActivitiesListVM['listPaginationData'] | undefined;
type MakeGetTrackedActivitiesListFilters = TrackedActivitiesList['params'];

export const makeGetTrackedActivitiesListData = () =>
  createSelector<trackedActivitiesReducer, TrackedActivitiesList, MakeGetTrackedActivitiesListData>(getTrackedActivitiesList, ({ data }) => data?.list || []);

export const makeGetTrackedActivitiesListStatus = () =>
  createSelector<trackedActivitiesReducer, TrackedActivitiesList, ReturnType<typeof getDataStatus>>(getTrackedActivitiesList, getDataStatus);

export const makeGetTrackedActivitiesListPaginationData = () =>
  createSelector<trackedActivitiesReducer, TrackedActivitiesList, MakeGetTrackedActivitiesListPaginationData>(
    getTrackedActivitiesList,
    ({ data }) => data?.listPaginationData
  );

export const makeGetTrackedActivitiesListFilters = () =>
  createSelector<trackedActivitiesReducer, TrackedActivitiesList, MakeGetTrackedActivitiesListFilters>(getTrackedActivitiesList, ({ params }) => params);

const getTrackedActivityDetails = (state: trackedActivitiesReducer) => state.trackedActivities.details;

type trackedActivityDetails = trackedActivitiesReducer['trackedActivities']['details'];
type MakeGetTrackedActivityDetailsData = trackedActivityDetails['data'];

export const makeGetTrackedActivityDetailsData = () =>
  createSelector<trackedActivitiesReducer, trackedActivityDetails, MakeGetTrackedActivityDetailsData>(getTrackedActivityDetails, ({ data }) => data);

export const makeGetTrackedActivityDetailsStatus = () =>
  createSelector<trackedActivitiesReducer, trackedActivityDetails, ReturnType<typeof getDataStatus>>(getTrackedActivityDetails, getDataStatus);

const getTrackedActivityThumbnail = (state: trackedActivitiesReducer) => state.trackedActivities.thumbnail;

type TrackedActivityThumbnail = trackedActivitiesReducer['trackedActivities']['thumbnail'];
type MakeGetTrackedActivityThumbnailData = TrackedActivityThumbnail['data'];

export const makeGetTrackedActivityThumbnailData = () =>
  createSelector<trackedActivitiesReducer, TrackedActivityThumbnail, MakeGetTrackedActivityThumbnailData>(getTrackedActivityThumbnail, ({ data }) => data);
