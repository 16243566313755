import { filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Epic } from 'redux-observable';

import {
  getTrackedActivities,
  getTrackedActivityDetailsData,
  getTrackedActivityThumbnailData,
} from '@/features/content/services/tracked-activities/tracked-activities';
import { CommonError, untilLocationChange } from '@/common/utils';
import { trackedActivitiesActions } from './actions';
import { trackedActivitiesReducer } from './reducers';

const getTrackedActivitiesList: Epic<trackedActivitiesActions, trackedActivitiesActions, trackedActivitiesReducer> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(trackedActivitiesActions.list.request)),
    switchMap(() =>
      getTrackedActivities(state$.value.trackedActivities.list.params).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return trackedActivitiesActions.list.failure(response);
          }

          return trackedActivitiesActions.list.success(response);
        })
      )
    )
  );

const getTrackedActivityDetails: Epic<trackedActivitiesActions, trackedActivitiesActions, trackedActivitiesReducer> = action$ =>
  action$.pipe(
    filter(isActionOf(trackedActivitiesActions.details.request)),
    switchMap(action =>
      getTrackedActivityDetailsData(action.payload.params).pipe(
        map(response => {
          if (response instanceof CommonError) {
            return trackedActivitiesActions.details.failure(response);
          }

          return trackedActivitiesActions.details.success(response);
        })
      )
    )
  );

const getTrackedActivityThumbnail: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(trackedActivitiesActions.thumbnail.request)),
    switchMap(action =>
      getTrackedActivityThumbnailData(action.payload.params)
        .pipe(untilLocationChange(action$))
        .pipe(
          map(response => {
            if (response instanceof CommonError) {
              return trackedActivitiesActions.thumbnail.failure(response);
            }

            return trackedActivitiesActions.thumbnail.success(response);
          })
        )
    )
  );

export const trackedActivitiesEpics = [getTrackedActivitiesList, getTrackedActivityDetails, getTrackedActivityThumbnail];
