import dayjs, { Dayjs } from 'dayjs';

import { DEFAULT_DATE_FORMAT } from '@/common/utils';
import { type ApiPagination, type PageableListResponse, PageableListVM } from '@/common/utils/pageable';
import { getRouteDistanceInKilometres } from '@/features/content/services/routes';
import { getActivityFormattedTime } from '@/features/content/services/tracked-activities/functions';
import { type AutocompleteOption } from '@/common/components/autocomplete';

export enum HasGeometryValues {
  Yes = 'YES',
  No = 'NO',
}
export enum RecordStatusValues {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
}

export type TrackedActivityParams = ApiPagination<{
  activityName?: string;
  activityId?: string;
  searchType?: string;
  region?: string;
  status?: string;
  createdBy?: AutocompleteOption;
  createdAtFrom?: Dayjs;
  createdAtTo?: Dayjs;
  distanceFrom?: number;
  distanceTo?: number;
  activityTypes?: string[];
  hasGeometry?: string;
}>;

export type TrackedActivityEntityResponse = {
  id: string;
  characteristics: {
    activity: string;
    date: string;
    distance: number;
    duration: number;
    movingTime: number;
    routeId?: string;
  };
  hasGeometry: boolean;
  metadata: {
    createdAt: string;
    createdBy: string;
    description: string;
    lastModifiedBy: string;
    modifiedAt: string;
    name: string;
    origin: {
      authorDetails: {
        firstName: string;
        lastName: string;
      };
      createdBy: string;
      sourceApplication: {
        appVersion: string;
        applicationName: string;
        deviceModel: string;
        osVersion: string;
        platform: string;
      };
    };
    status: string;
    thumbnailUrl: string;
  };
};

export type TrackedActivitiesListResponse = PageableListResponse<TrackedActivityEntityResponse>;

export interface TrackedActivitiesElementVM extends TrackedActivityEntityResponse {}
export class TrackedActivitiesElementVM {
  constructor({ characteristics, metadata, ...props }: TrackedActivityEntityResponse) {
    Object.assign(this, {
      ...props,
      characteristics: {
        ...characteristics,
        distance: getRouteDistanceInKilometres(characteristics.distance),
        duration: getActivityFormattedTime(characteristics.duration),
        movingTime: getActivityFormattedTime(characteristics.movingTime),
      },
      metadata: {
        ...metadata,
        createdAt: metadata.createdAt ? dayjs(metadata.createdAt).format(DEFAULT_DATE_FORMAT) : '',
      },
    });
  }
}

export class TrackedActivitiesListVM extends PageableListVM<TrackedActivitiesElementVM, TrackedActivitiesListResponse> {}

export type TrackedActivityDetailsResponse = {
  id: string;
  characteristics: {
    activity: string;
    date: string;
    distance: number;
    duration: number;
    movingTime: number;
    routeId?: string;
  };
  hasGeometry: boolean;
  metadata: {
    createdAt: string;
    createdBy: string;
    description: string;
    lastModifiedBy: string;
    modifiedAt: string;
    name: string;
    origin: {
      authorDetails: {
        firstName: string;
        lastName: string;
      };
      createdBy: string;
      sourceApplication: {
        appVersion: string;
        applicationName: string;
        deviceModel: string;
        osVersion: string;
        platform: string;
      };
    };
    status: string;
    thumbnailUrl: string;
  };
};

export interface TrackedActivityDetailsVM extends TrackedActivityDetailsResponse {}
export class TrackedActivityDetailsVM {
  constructor({ metadata, characteristics, ...props }: TrackedActivityDetailsResponse) {
    Object.assign(this, {
      ...props,
      characteristics: {
        ...characteristics,
        distance: getRouteDistanceInKilometres(characteristics.distance),
        duration: getActivityFormattedTime(characteristics.duration),
        movingTime: getActivityFormattedTime(characteristics.movingTime),
      },
      metadata: {
        ...metadata,
        createdAt: metadata.createdAt ? dayjs(metadata.createdAt).format(DEFAULT_DATE_FORMAT) : '',
      },
    });
  }
}

export type TrackedActivityThumbnailResponse = string;

export type TrackedActivityThumbnailVM = TrackedActivityThumbnailResponse;

export type TrackedActivityThumbnailParam = {
  url: string;
};
