import { Enum } from '@/common/utils';
import { RoutePaths } from '../../navigation.models';
import { authenticatedRoutePaths } from './authenticated';

export type contentRoutes = Enum<typeof contentRoutes>;

export const contentRoutes = Enum(
  'ROUTES_LIST',
  'ROUTES_BULK_CHANGE',
  'ROUTES_BULK_RESTORE',
  'ROUTES_DETAILS',
  'ROUTES_UPLOAD',
  'TOURS_LIST',
  'TOUR_DETAILS',
  'ADD_TOUR',
  'STORY_DETAILS',
  'STORY_ADD',
  'THINGS_TO_DO_LIST',
  'THINGS_TO_DO_DETAILS',
  'ADD_THINGS_TO_DO',
  'THINGS_TO_DO_TAGS_LIST',
  'THINGS_TO_DO_TAG_DETAILS',
  'ADD_THINGS_TO_DO_TAG',
  'COMMUNITY_STORIES_LIST',
  'COMMUNITY_STORY_DETAILS',
  'REVIEW_LIST',
  'REVIEW_DETAILS',
  'GALLERY',
  'PINS_LIST',
  'PIN_DETAILS',
  'ACTIVITIES_LIST',
  'TRACKED_ACTIVITY_DETAILS'
);

export const contentRoutePaths: RoutePaths<contentRoutes> = {
  [contentRoutes.ROUTES_LIST]: `${authenticatedRoutePaths.CONTENT}/routes`,
  [contentRoutes.ROUTES_BULK_CHANGE]: `${authenticatedRoutePaths.CONTENT}/routes/bulk-change`,
  [contentRoutes.ROUTES_BULK_RESTORE]: `${authenticatedRoutePaths.CONTENT}/routes/bulk-restore`,
  [contentRoutes.ROUTES_DETAILS]: `${authenticatedRoutePaths.CONTENT}/routes/:id`,
  [contentRoutes.ROUTES_UPLOAD]: `${authenticatedRoutePaths.CONTENT}/routes/add`,
  [contentRoutes.TOURS_LIST]: `${authenticatedRoutePaths.CONTENT}/tours`,
  [contentRoutes.TOUR_DETAILS]: `${authenticatedRoutePaths.CONTENT}/tours/:id`,
  [contentRoutes.STORY_DETAILS]: `${authenticatedRoutePaths.CONTENT}/tours/story/:storyId`,
  [contentRoutes.ADD_TOUR]: `${authenticatedRoutePaths.CONTENT}/tours/add`,
  [contentRoutes.STORY_ADD]: `${authenticatedRoutePaths.CONTENT}/tours/:tourId/story/add`,
  [contentRoutes.STORY_ADD]: `${authenticatedRoutePaths.CONTENT}/tours/story/add?tourId=:tourId`,
  [contentRoutes.THINGS_TO_DO_LIST]: `${authenticatedRoutePaths.CONTENT}/ttd`,
  [contentRoutes.THINGS_TO_DO_DETAILS]: `${authenticatedRoutePaths.CONTENT}/ttd/:id`,
  [contentRoutes.ADD_THINGS_TO_DO]: `${authenticatedRoutePaths.CONTENT}/ttd/add`,
  [contentRoutes.THINGS_TO_DO_TAGS_LIST]: `${authenticatedRoutePaths.CONTENT}/ttd/tags`,
  [contentRoutes.ADD_THINGS_TO_DO_TAG]: `${authenticatedRoutePaths.CONTENT}/ttd/tags/add`,
  [contentRoutes.THINGS_TO_DO_TAG_DETAILS]: `${authenticatedRoutePaths.CONTENT}/ttd/tags/:id`,
  [contentRoutes.COMMUNITY_STORIES_LIST]: `${authenticatedRoutePaths.CONTENT}/community-stories`,
  [contentRoutes.COMMUNITY_STORY_DETAILS]: `${authenticatedRoutePaths.CONTENT}/community-stories/:id`,
  [contentRoutes.REVIEW_LIST]: `${authenticatedRoutePaths.CONTENT}/reviews`,
  [contentRoutes.REVIEW_DETAILS]: `${authenticatedRoutePaths.CONTENT}/reviews/:id`,
  [contentRoutes.GALLERY]: `${authenticatedRoutePaths.CONTENT}/gallery`,
  [contentRoutes.PINS_LIST]: `${authenticatedRoutePaths.CONTENT}/pins`,
  [contentRoutes.PIN_DETAILS]: `${authenticatedRoutePaths.CONTENT}/pins/:id`,
  [contentRoutes.ACTIVITIES_LIST]: `${authenticatedRoutePaths.CONTENT}/activities`,
  [contentRoutes.TRACKED_ACTIVITY_DETAILS]: `${authenticatedRoutePaths.CONTENT}/activities/:id`,
};

export type contentRouteParams = {
  [contentRoutes.ROUTES_DETAILS]: { id: string };
  [contentRoutes.STORY_DETAILS]: { storyId: string };
  [contentRoutes.STORY_ADD]: { tourId: string };
  [contentRoutes.THINGS_TO_DO_TAG_DETAILS]: { id: string };
  [contentRoutes.TOUR_DETAILS]: { id: string };
  [contentRoutes.THINGS_TO_DO_DETAILS]: { id: string };
  [contentRoutes.COMMUNITY_STORY_DETAILS]: { id: string };
  [contentRoutes.REVIEW_DETAILS]: { id: string };
  [contentRoutes.PIN_DETAILS]: { id: string };
  [contentRoutes.TRACKED_ACTIVITY_DETAILS]: { id: string };
};
