import { Action, ActionType, createAction } from 'typesafe-actions';

import { createFetchActions } from '@/common/utils/store';
import {
  TrackedActivitiesListVM,
  TrackedActivityDetailsVM,
  TrackedActivityParams,
  TrackedActivityThumbnailParam,
  TrackedActivityThumbnailVM,
} from '@/models/tracked-activities';

export const trackedActivitiesActions = {
  list: createFetchActions('FETCH_TRACKED_ACTIVITIES_LIST_REQUEST', 'FETCH_TRACKED_ACTIVITIES_LIST_SUCCESS', 'FETCH_TRACKED_ACTIVITIES_LIST_FAILURE')<
    TrackedActivityParams,
    TrackedActivitiesListVM
  >(),
  details: createFetchActions('FETCH_TRACKED_ACTIVITY_DETAILS_REQUEST', 'FETCH_TRACKED_ACTIVITY_DETAILS_SUCCESS', 'FETCH_TRACKED_ACTIVITY_DETAILS_FAILURE')<
    string,
    TrackedActivityDetailsVM
  >(),
  clearTrackedActivityDetails: createAction('CLEAR_TRACKED_ACTIVITY_DETAILS')(),
  thumbnail: createFetchActions(
    'FETCH_TRACKED_ACTIVITY_THUMBNAIL_REQUEST',
    'FETCH_TRACKED_ACTIVITY_THUMBNAIL_SUCCESS',
    'FETCH_TRACKED_ACTIVITY_THUMBNAIL_FAILURE'
  )<TrackedActivityThumbnailParam, TrackedActivityThumbnailVM>(),
};

export type trackedActivitiesActions = Action | ActionType<typeof trackedActivitiesActions>;
