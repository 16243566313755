import React, { type FC, useCallback, useMemo } from 'react';
import { withHistory } from 'slate-history';
import isUrl from 'is-url';
import { createEditor, Descendant } from 'slate';
import { Slate, Editable, withReact, useSelected, useFocused } from 'slate-react';

import { useStyles } from '@/styles/hooks';
import { css } from '@emotion/react';
import { IconButton } from '@/common/components/icon-button';
import { Toolbar } from './components';
import { textEditorStyles } from './style';

import { isLinkActive, unwrapLink, wrapLink } from './functions';

export type ButtonElement = { type: 'button'; children: Descendant[] };
export type LinkElement = { type: 'link'; url: string; children: Descendant[] };

export type TextEditorProps = {
  initialValue: any;
  onChange: (value) => void;
  shouldBeInitialized: boolean;
};

export const httpProtocol = 'http://';
export const httpsProtocol = 'https://';
export const defaultProtocol = httpProtocol;

const defaultComponentValue = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

export const TextEditor: FC<TextEditorProps> = ({ initialValue, onChange, shouldBeInitialized }) => {
  const { styles } = useStyles(textEditorStyles);

  const withInlines = editor => {
    const { insertData, insertText, isInline, isElementReadOnly, isSelectable } = editor;

    editor.isInline = element => ['link', 'button', 'badge'].includes(element.type) || isInline(element);

    editor.isElementReadOnly = element => element.type === 'badge' || isElementReadOnly(element);

    editor.isSelectable = element => element.type !== 'badge' && isSelectable(element);

    editor.insertText = text => {
      if (text && isUrl(text)) {
        wrapLink(editor, text);
      } else {
        insertText(text);
      }
    };

    editor.insertData = data => {
      const text = data.getData('text/plain');

      if (text && isUrl(text)) {
        wrapLink(editor, text);
      } else {
        insertData(data);
      }
    };

    return editor;
  };

  const editor = useMemo(() => withInlines(withHistory(withReact(createEditor()))), []);

  const removeLink = (editor, event) => {
    event.preventDefault();

    if (isLinkActive(editor)) {
      unwrapLink(editor);
    }
  };

  // Put this at the start and end of an inline component to work around this Chromium bug:
  // https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
  const InlineChromiumBugfix = () => (
    <span contentEditable={false} className='chromium-helper'>
      {String.fromCodePoint(160) /* Non-breaking space */}
    </span>
  );

  const LinkComponent = ({ attributes, children, element }) => {
    const selected = useSelected();
    const focused = useFocused();

    return (
      <span className='element-link'>
        <a
          {...attributes}
          href={element.url}
          className={
            selected
              ? css`
                  box-shadow: 0 0 0 3px #ddd;
                `
              : ''
          }
        >
          <InlineChromiumBugfix />
          <>
            {children}
            {selected && focused && (
              <span className='link-popup' contentEditable={false}>
                <span className='url-value'>{element.url}</span>
                <IconButton type='button' iconName='mdi-link-off' onClick={event => removeLink(editor, event)} />
              </span>
            )}
          </>
          <InlineChromiumBugfix />
        </a>
      </span>
    );
  };

  const Element = props => {
    const { children, element } = props;

    switch (element.type) {
      case 'link':
        return <LinkComponent {...props} />;
      case 'br':
        return '\n';
      default:
        return children;
    }
  };

  const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
      // eslint-disable-next-line no-param-reassign
      children = <strong>{children}</strong>;
    }

    if (leaf.code) {
      // eslint-disable-next-line no-param-reassign
      children = <code>{children}</code>;
    }

    if (leaf.italic) {
      // eslint-disable-next-line no-param-reassign
      children = <em>{children}</em>;
    }

    if (leaf.underline) {
      // eslint-disable-next-line no-param-reassign
      children = <u>{children}</u>;
    }

    return <span {...attributes}>{children}</span>;
  };

  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);

  return shouldBeInitialized ? (
    <Slate key={'specified-editor'} editor={editor} initialValue={initialValue} onChange={onChange}>
      <Toolbar />
      <Editable css={styles} renderElement={props => renderElement({ ...props, editor })} renderLeaf={renderLeaf} placeholder='' />
    </Slate>
  ) : (
    <Slate key={'default-editor'} editor={editor} initialValue={defaultComponentValue} onChange={onChange}>
      <Toolbar />
      <Editable css={styles} renderElement={props => renderElement({ ...props, editor })} renderLeaf={renderLeaf} placeholder='' />
    </Slate>
  );
};
