import React, { FC, ReactNode } from 'react';
import { useSlate } from 'slate-react';
import { useTranslation } from 'react-i18next';
import { useStyles } from '@/styles/hooks';
import { IconButton } from '@/common/components/icon-button';
import { toolbarStyles } from './styles';
import { insertLink } from '../../functions';
import { defaultProtocol, httpProtocol, httpsProtocol } from '../../text-editor';

type ToolbarProps = {
  children?: ReactNode[];
};

export const Toolbar: FC<ToolbarProps> = () => {
  const [t] = useTranslation();
  const { styles } = useStyles(toolbarStyles);

  const AddLinkButton = () => {
    const editor = useSlate();

    const addProtocol = (url: string) => {
      if (url.startsWith(httpProtocol) || url.startsWith(httpsProtocol)) {
        return url;
      }
      return `${defaultProtocol}${url}`;
    };

    return (
      <IconButton
        type='button'
        onMouseDown={event => {
          event.preventDefault();
          const url = window.prompt(t('label.enterTheUrl'));
          if (!url) return;
          insertLink(editor, addProtocol(url));
        }}
        className='action-button'
        iconName='mdi-link'
      />
    );
  };

  return (
    <div css={styles}>
      <AddLinkButton />
    </div>
  );
};
