import { combineReducers } from 'redux';

import { type FeatureStateType } from '../../helpers';
import { trackedActivitiesListReducer } from './list';
import { trackedActivityDetailsReducer } from './details';
import { trackedActivityThumbnailReducer } from './thumbnail';

export type trackedActivitiesReducer = FeatureStateType<typeof trackedActivitiesReducer>;

export const trackedActivitiesReducer = {
  trackedActivities: combineReducers({
    ...trackedActivitiesListReducer,
    ...trackedActivityDetailsReducer,
    ...trackedActivityThumbnailReducer,
  }),
};
