import { createReducer } from 'typesafe-actions';

import { FetchState, getFetchInitialState, fetchReducerHelpers } from '@/common/utils/store';
import { type TrackedActivityDetailsVM } from '@/models/tracked-activities';
import { type FeatureStateType } from '../../helpers';
import { trackedActivitiesActions } from '../actions';

export type trackedActivityDetailsState = FetchState<TrackedActivityDetailsVM>;

const INITIAL_STATE: trackedActivityDetailsState = getFetchInitialState<TrackedActivityDetailsVM>();

const reducer = createReducer<trackedActivityDetailsState, trackedActivitiesActions>(INITIAL_STATE)
  .handleAction(trackedActivitiesActions.details.request, (state, { payload }) => fetchReducerHelpers.request(INITIAL_STATE, state, payload))
  .handleAction(trackedActivitiesActions.details.success, (state, { payload }) => fetchReducerHelpers.success(INITIAL_STATE, state, payload))
  .handleAction(trackedActivitiesActions.details.failure, (state, { payload }) => fetchReducerHelpers.failure(INITIAL_STATE, state, payload))
  .handleAction(trackedActivitiesActions.clearTrackedActivityDetails, () => ({ ...INITIAL_STATE }));

export const trackedActivityDetailsReducer = { details: reducer };
export type trackedActivityDetailsReducer = FeatureStateType<typeof trackedActivityDetailsReducer>;
